import React from 'react';
import Carousel from './Carousel';
import './MainStageView.css'
import {useState, createContext } from "react";

import './Stage.css'



export const Stagecontent = createContext("");


const Stage = () => {

  const [stagecontent, setStagecontent] = useState("");
 // const [stageSize, setStageSize] = useState({ height: '100%' });

  const sContent = (content) => {
    setStagecontent(content)
  }





  return <div className='stage'>

      <Stagecontent.Provider value={{ stagecontent, sContent }}>
        <div className='main-stage-view' id='main-stage-view'>
          { stagecontent }
        </div>
        <Carousel />
      </Stagecontent.Provider>

  </div>
}
export default Stage;
