import React from 'react';
import TextBlock from './TextBlock';
import {useState, useEffect} from "react";
import globalVars from '../../GlobalVars';



import './TextBlocks.css'

const query = `
{
   pageCollection {
        items {
        
        settings { 
             uriPathSlug
             }

      
      title(locale:"`+globalVars.activeLang+`")
      text(locale:"`+globalVars.activeLang+`")
      alignImageAtTheBottom
      teaserImage {
      url 
      title
      }
      
  
  moreTextsCollection(limit: 20, locale:"`+globalVars.activeLang+`") {
    items {
      title
      text 
    }
  }
}
}
}
`

const TextBlocks = (props) => {
    const [page, setPage] = useState(null);

    useEffect(() => {
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/xpnhamgzub47/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // Authenticate the request
                    Authorization: "Bearer erIN_NYrQh4UG3i5soUP0DXO4Wm-n2J34SRwUtvQGew",
                },
                // send the GraphQL query
                body: JSON.stringify({ query }),
            })
            .then((response) => response.json())
            .then(({ data, errors }) => {
                if (errors) {
                    console.error(errors);
                }
                // rerender the entire component with new data
                // setPage(data.textContentCollection.items[globalVars.getpageid(data.pageCollection,'settings')]);
                setPage(data);
            });
    }, []);

    // show a loading screen case the data hasn't arrived yet
    if (!page) {
        return "";
    }



    return (
        <div className='text-blocks'>
            <div className='column-left' style={(() => {
                if (page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')]?.teaserImage?.url && page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')]?.alignImageAtTheBottom) {
                    let bgImage = page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')]?.teaserImage?.url;
                    return (
                        { backgroundImage: 'url('+ bgImage +')' }
                    )
                }
            })()}>
                <TextBlock
                    blockheadline={page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')].title}
                    blocktext={page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')].text}
                />
                {(() => {
                    if (page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')]?.teaserImage?.url) {
                        return (<img className={(() => {
                            if (page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')]?.alignImageAtTheBottom) {
                                return (
                                    'teaserBgImage'
                                )
                            }
                        })()} src={page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')]?.teaserImage?.url} alt={page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')]?.teaserImage?.title}/>);
                    }
                })()}
            </div>
            <div className='column-right'>

                {(() => {
                    let td = [];
                    for (const key in page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')].moreTextsCollection.items) {

                        td.push(<TextBlock key={key}
                            blockheadline={page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')].moreTextsCollection.items[key].title}
                            blocktext={page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')].moreTextsCollection.items[key].text}
                        />);
                    }
                    return td;
                })()}



            </div>
        </div>
    );
}
export default TextBlocks;
