import React from 'react';
import globalVars from '../../GlobalVars';

import './HeaderContent.css'
import {useState, useEffect} from "react";
import LangSwitch from "./LangSwitch";




const query = `
{
  pagesettingsCollection(locale:"`+globalVars.activeLang+`") {
    items {
      languageSelectionYes
      uriPathSlug
      backgroundColor
      colorGradientValue
      fontColor
      titleColor
      brandLogo {
        url
      }
    }
  }
}
`

const HeaderContent = () => {
    const [page, setPage] = useState(null);




    useEffect(() => {
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/xpnhamgzub47/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // Authenticate the request
                    Authorization: "Bearer erIN_NYrQh4UG3i5soUP0DXO4Wm-n2J34SRwUtvQGew",
                },
                // send the GraphQL query
                body: JSON.stringify({query}),
            })
            .then((response) => response.json())
            .then(({data, errors}) => {
                if (errors) {
                    console.error(errors);
                }

                // rerender the entire component with new data
                setPage(data);
            });



    }, []);


    // show a loading screen case the data hasn't arrived yet
    if (!page) {
        return "";
    }

    const showLangSwitch = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].languageSelectionYes;
    const brandLogoSrc = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].brandLogo;
    const fontColor = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].fontColor;
    const colorGradientValue = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].colorGradientValue;

    const header = document.getElementsByClassName("header");

    const stageView = document.getElementById("main-stage-view");

    stageView.style.border="2px solid "+fontColor




    let rgb;


    if(colorGradientValue != null) {
        if (colorGradientValue.indexOf('#') >= 0) {
            rgb = globalVars.hexToRgb(colorGradientValue);
            header[0].style.backgroundImage = 'linear-gradient(180deg, rgba(' + rgb['r'] + ',' + rgb['g'] + ',' + rgb['b'] + ',1) 60%, rgba(' + rgb['r'] + ',' + rgb['g'] + ',' + rgb['b'] + ',0) 100%)';

        } else if (colorGradientValue.indexOf('rgb') >= 0) {

            rgb = globalVars.getRGB(colorGradientValue);

            header[0].style.backgroundImage = 'linear-gradient(180deg, rgba(' + rgb['r'] + ',' + rgb['g'] + ',' + rgb['b'] + ',' + rgb['a'] + ') 60%, rgba(' + rgb['r'] + ',' + rgb['g'] + ',' + rgb['b'] + ',0) 100%)';
        }
    }







    //hideBgHeader

    if (showLangSwitch === true && brandLogoSrc !== null) {
        return (
            <div className="header-content">
                <LangSwitch/>
                <img src={brandLogoSrc.url} alt="" className="brand-logo"/>
            </div>
        );
    } else if (showLangSwitch === true && brandLogoSrc === null) {
        return (
            <div className="header-content">
                <LangSwitch/>
            </div>
        );
    } else {
        return (
            <div className="header-content">
            </div>
        );
    }

}
export default HeaderContent;
