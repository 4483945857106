import './App.css';

import BackgroundContainer from "./components/BackgroundContainer/BackgroundContainer";
import Header from "./components/Header/Header";
import CaseLogo from "./components/CaseLogo/CaseLogo";
import HeaderContent from "./components/Header/HeaderContent";
import Stage from "./components/Stage/Stage";
import TextBlocks from "./components/TextBlocks/TextBlocks";
import FooterLogo from "./components/FooterLogo/FooterLogo";
import globalVars from './GlobalVars';
import {useState, useEffect} from "react";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

let notPublic = true;
let passwordPrompt = false;
let loggedIn = false;

const query = `
{
    pagesettingsCollection {
      items {
        pageTitle(locale:"`+globalVars.activeLang+`")
        uriPathSlug
        favIcon {
          url
        }
        fontColor
        titleColor
      }
    }
    pageCollection {
        items {
            public
            password
            settings { 
             uriPathSlug
             }
        }
    }
}
`



function App() {


    const [page, setPage] = useState(null);
    const [previewYes, setPreviewYes] = useState(false);




    useEffect(() => {
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/xpnhamgzub47/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // Authenticate the request
                    Authorization: "Bearer erIN_NYrQh4UG3i5soUP0DXO4Wm-n2J34SRwUtvQGew",
                },
                body: JSON.stringify({query}),
            })
            .then((response) => response.json())
            .then(({data, errors}) => {
                if (errors) {
                    console.error(errors);
                }


                setPage(data);
            });
    }, []);


    if (!page) {
        return "Loading...";
    }



    window.onresize = () =>{
        setPage(page);
    }

    let pageId = globalVars.getpageid(page.pagesettingsCollection);

    if(pageId !== -1) {
        document.title = page.pagesettingsCollection.items[pageId].pageTitle;

        const publicYes = page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')]?.public;
        const password_preview = page.pageCollection.items[globalVars.getpageid(page.pageCollection,'settings')]?.password;




            let promptCount = 0;
            const preview_password = (options) => {
                let lm = options.lm || "Password:",
                    bm = options.bm || "Submit",
                    errorMsg = "Wrong password.";
                if(!options.callback) {
                    alert("No callback function provided! Please provide one.")
                };

                let prompt = document.createElement("div");
                prompt.className = "preview_password";

                let opt = document.createElement("input");
                opt.id = "preview_password_save"+ (++promptCount);
                opt.type = "checkbox";
                opt.value = "1";
                opt.checked = !!cookies.get('previewlogin_'+pageId);

                let submit = function(e) {

                    e.preventDefault()
                    options.callback(input.value, opt.checked);
                };

                let label = document.createElement("label");
                label.textContent = lm;
                label.for = "preview_password_input" + (++promptCount);
                prompt.appendChild(label);

                let input = document.createElement("input");
                input.id = "preview_password_input" + (promptCount);
                input.type = "password";

                if(cookies.get('previewlogin_'+pageId)===password_preview) {
                    input.value = "*".repeat(password_preview.length);
                }

                input.addEventListener("keyup", function(e) {
                    if (e.keyCode === 13) submit();
                }, false);
                prompt.appendChild(input);

                let button = document.createElement("button");
                button.textContent = bm;
                button.addEventListener("click", submit, false);
                prompt.appendChild(button);

                let label_opt = document.createElement("label");
                label_opt.textContent = "Save password?";
                label_opt.for = "preview_password_save" + (++promptCount);
                prompt.appendChild(label_opt);
                label_opt.prepend(opt);

                let msg = document.createElement("span");
                msg.textContent = errorMsg;
                msg.id = 'preview_password_error';
                prompt.appendChild(msg);

                let form = document.createElement("form");
                form.action = "#"
                form.method = "post"
                form.id = "preview_password";
                form.appendChild(prompt);

                document.body.appendChild(form);


            };

        if(cookies.get('previewlogin_'+pageId)===password_preview && cookies.get('previewlogin_'+pageId)) {
            loggedIn = true;
        }

        if(publicYes !== true  && password_preview !== null && previewYes !== true && loggedIn === false) {

            passwordPrompt = true;

            preview_password({
                lm:"Please enter the preview password:",
                errorMsg:"error!!",
                callback: function(password,cookie_yes) {
                    document.getElementById('preview_password_error').style.display = 'none';
                    if(password_preview===password || cookies.get('previewlogin_'+pageId)===password_preview) {

                        if(cookie_yes) {
                            cookies.set('previewlogin_'+pageId, password_preview, { path: '/', sameSite: true, maxAge: 60*60*24  });
                        } else {
                            cookies.remove('previewlogin_'+pageId, { path: '/', secure: true, sameSite: true });
                        }

                        document.body.removeChild(document.getElementById('preview_password'));
                        setPreviewYes(true);
                    } else {
                        document.getElementById('preview_password_error').style.display = 'block';
                    }

                }
            });

        }



        if(publicYes === true || previewYes === true || loggedIn === true) {


            const globalFontColor = page.pagesettingsCollection.items[pageId]?.fontColor;
            let titleColor = page.pagesettingsCollection.items[pageId].titleColor;

            if (titleColor == null) {
                titleColor = globalFontColor;
            }

            let favIcon = page.pagesettingsCollection.items[pageId]?.favIcon?.url;

            const favIconElement = document.getElementById("favicon32x32");
            const favIconElementLarge = document.getElementById("favicon32x32");
            const favIconElementTouch = document.getElementById("favicontouch");

            if (favIcon) {
                favIconElement.href = page.pagesettingsCollection.items[pageId]?.favIcon?.url;
                favIconElementLarge.href = page.pagesettingsCollection.items[pageId]?.favIcon?.url;
                favIconElementTouch.href = page.pagesettingsCollection.items[pageId]?.favIcon?.url;
            }

            notPublic = false;


            return (
                <BackgroundContainer>
                    <style>
                        {`    
                .lang-switcher-option, lang-switcher-option:active, .lang-switcher-option.active, .lang-switcher-option:visited {
                    color: ` + globalFontColor + `;
                }
                
                .text-block-headline {
                  color: ` + titleColor + `;
                }
              
            `}
                    </style>
                    <Header>
                        <HeaderContent/>
                    </Header>
                    <CaseLogo/>
                    <Stage/>
                    <TextBlocks/>
                    <FooterLogo/>
                </BackgroundContainer>
            );

        }

    }

    if(passwordPrompt === true && notPublic === true) {

        document.title = 'Password required';
        return (
            <div></div>
        );

    } else if(notPublic === true) {

        document.title = 'We\'re sorry... 404';
        return (
            <div className="errormsg" id="errormsg">
                <h1>We're sorry...</h1>
                <p>Unfortunately, this case does not exist or no longer exists or has been renamed.</p>
            </div>
        );
    }
}
export default App
