import './CaseLogo.css';
import {useState, useEffect} from "react";
import globalVars from "../../GlobalVars";

const query = `
{
    pageCollection {
        items  {
            pageTitle(locale:"`+globalVars.activeLang+`")
            caseLogo(locale:"`+globalVars.activeLang+`") {
              url
            }
            settings { 
             uriPathSlug
             languageSelectionYes
             brandLogo(locale:"`+globalVars.activeLang+`") { 
                url 
             }
            }
        }
    }
}
`


const CaseLogo = () => {
    // define the initial state
    const [page, setPage] = useState(null);

    useEffect(() => {
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/xpnhamgzub47/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // Authenticate the request
                    Authorization: "Bearer erIN_NYrQh4UG3i5soUP0DXO4Wm-n2J34SRwUtvQGew",
                },
                // send the GraphQL query
                body: JSON.stringify({ query }),
            })
            .then((response) => response.json())
            .then(({ data, errors }) => {
                if (errors) {
                    console.error(errors);
                }



                // rerender the entire component with new data
                setPage(data.pageCollection.items[globalVars.getpageid(data.pageCollection,'settings')]);
            });
    }, []);

    // show a loading screen case the data hasn't arrived yet
    if (!page) {
        return "";
    }



    const showLangSwitch = page.settings?.languageSelectionYes;
    // const brandLogoSrc = page.settings?.brandLogo;

    if (showLangSwitch === true ) {
        return (
            <div className="case-logo-area">
                <img src={page.caseLogo?.url} alt={page.pageTitle} className="case-logo-img"/>
            </div>
        );
    } else {
        return (
            <div className="case-logo-area-top">
                <img src={page.caseLogo?.url} alt={page.pageTitle} className="case-logo-img"/>
            </div>
        );
    }


}
export default CaseLogo;
