import React from 'react';
import { useEffect } from "react";
import './Header.css';


const Header = (props) => {

    const [classname, setClassname] = React.useState("header hideBgHeader")


    useEffect(() => {
        const handleScroll = () => {
            if(window.scrollY>100) {
                setClassname("header");
            } else {
                setClassname("header hideBgHeader");
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    return (
        <header className={ classname }>
            {props.children}
        </header>
    );
};

export default Header;
