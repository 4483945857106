import React from 'react';
import './LangSwitch.css';
import globalVars from '../../GlobalVars';



const LangSwitch = () => {

    const SetLang = (lang,event) => {
        globalVars.activeLang = lang.replace('#','');
        event.preventDefault();
        window.location.href = window.location.pathname + '#' + globalVars.getpage + '|'+lang;
        window.location.reload()
    }

    const SetActive = (lang) => {
        if(globalVars.activeLang === lang) {
            return 'lang-switcher-option active';
        } else {
            return 'lang-switcher-option'
        }
    }
    return(
        <div data-lang-switcher className="lang-switcher">
            <a href="#en-US" data-lang="en" className={SetActive("en-US")}
                onClick={
                    (e) => {
                        SetLang("en-US",e);

                    }
                }
            >EN</a>
            &nbsp;|&nbsp;
            <a href="#de-DE" data-lang="de" className={SetActive("de-DE")}
               onClick={
                   (e) => {
                       SetLang("de-DE",e);
                   }
               }
            >DE</a>
        </div>
    );
}
export default LangSwitch;
