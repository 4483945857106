import './FooterLogo.css';
import {useState, useEffect} from "react";
import globalVars from '../../GlobalVars';

const query = `
{
  pagesettingsCollection(locale:"`+globalVars.activeLang+`") {
    items {
      pageTitle
      uriPathSlug
      footerLogo {
        url
      }
    }
  }
}
`


const FooterLogo = () => {
    // define the initial state
    const [page, setPage] = useState(null);

    useEffect(() => {
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/xpnhamgzub47/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // Authenticate the request
                    Authorization: "Bearer erIN_NYrQh4UG3i5soUP0DXO4Wm-n2J34SRwUtvQGew",
                },
                // send the GraphQL query
                body: JSON.stringify({ query }),
            })
            .then((response) => response.json())
            .then(({ data, errors }) => {
                if (errors) {
                    console.error(errors);
                }



                // rerender the entire component with new data
                setPage(data);
            });
    }, []);

    // show a loading screen case the data hasn't arrived yet
    if (!page) {
        return "";
    }



    const footerLogoSrc = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].footerLogo;
    const pageTitle = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].pageTitle;

    if(footerLogoSrc?.url != null) {
        return (
            <div className="footer-logo">
                <img src={footerLogoSrc?.url} alt={pageTitle} className="footer-logo-img"/>
            </div>
        );
    } else {
        return (<div className="footer-logo"></div>)
    }





}
export default FooterLogo;
