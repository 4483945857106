import React from 'react';
import globalVars from '../../GlobalVars';
import './BackgroundContainer.css';

import {useState, useEffect} from "react";


const query = `
{
    pagesettingsCollection {
        items {
            backgroundImageBody {
                url
            }
            backgroundImage {
                url
            }
            bodyBgImageParams
            uriPathSlug
            backgroundColor
            fontColor
            customCss
        }
    }
}
`

const BackgroundContainer = (props) => {

    // define the initial state
    const [page, setPage] = useState(null);

    useEffect(() => {
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/xpnhamgzub47/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // Authenticate the request
                    Authorization: "Bearer erIN_NYrQh4UG3i5soUP0DXO4Wm-n2J34SRwUtvQGew",
                },
                // send the GraphQL query
                body: JSON.stringify({query}),
            })
            .then((response) => response.json())
            .then(({data, errors}) => {
                if (errors) {
                    console.error(errors);
                }

                // rerender the entire component with new data
                setPage(data);
            });
    }, []);

    // show a loading screen case the data hasn't arrived yet
    if (!page) {
        return "";
    }

    const bgImage = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].backgroundImage;
    const bodyBgImage = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].backgroundImageBody;
    const bodyBgImageParams = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].bodyBgImageParams;
    const bgColor = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].backgroundColor;
    const globalFontColor = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].fontColor;
    const customCss = page.pagesettingsCollection.items[globalVars.getpageid(page.pagesettingsCollection)].customCss;
    const bodyElement = document.body;


 //   bodyElement.style.backgroundColor = bgColor;
    bodyElement.style.color = globalFontColor;

    let bgparams = bgColor;

    if (bodyBgImage != null) {
        bgparams+=' url("' + bodyBgImage.url + '") no-repeat center top'
    }

    if (bodyBgImageParams != null) {
        bgparams+=' '+bodyBgImageParams;
    }

    if (bgparams != null) {
        bodyElement.style.background = bgparams;
    }

    if (customCss != null) {

        let head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');


        head.appendChild(style);
        style.appendChild(document.createTextNode(customCss));

    }


    if (bgImage != null) {
        const bgStyles = {
            backgroundColor: '' + bgColor + '',
            backgroundImage: 'url("' + bgImage.url + '")'
        };
        return (
            <div className="background-container" style={bgStyles}>
                {props.children}
            </div>
        );
    } else if (bodyBgImage == null) {
        const bgStyles = {
          backgroundColor: '' + bgColor + ''
        };
        return (
            <div className="background-container" style={bgStyles}>
                {props.children}
            </div>
        );
    } else {
        return (
            <div className="background-container">
                {props.children}
            </div>
        );
    }



}
export default BackgroundContainer;
