
import React from 'react';

import {Navigation, Pagination} from 'swiper'
import {Swiper, SwiperSlide,} from "swiper/react";
import {useState, useEffect, useContext} from "react";
import globalVars from '../../GlobalVars';
import '../../mediaelementplayer-legacy.min.css';



//import ReactPlayer from "react-player/file";

import MediaElement from "./MediaElement.js";

import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './Carousel.css'
import { Stagecontent } from "./Stage";



const query = `
{
    pageCollection {
        items {
            settings { 
             fontColor
             uriPathSlug
             languageSelectionYes
             brandLogo {url}
            }
            
            sliderCollection(limit: 20, locale:"`+globalVars.activeLang+`") {
            items { 
            poster {
              url
            }
            streamUrl
            descriptionAlt
            }
            }
            
        }
    }
}
`

//content, setContent

// let last_innerWidth = window.innerWidth;

const Carousel = () => {




    // define the initial state
    const [page, setPage] = useState(null);
    const Context = useContext(Stagecontent);

    const handleStageChange = (content) => {

        Context.sContent(content);
    };








    useEffect(() => {
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/xpnhamgzub47/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer erIN_NYrQh4UG3i5soUP0DXO4Wm-n2J34SRwUtvQGew",
                },
                body: JSON.stringify({ query }),
            })
            .then((response) => response.json())
            .then(({ data, errors }) => {
                if (errors) {
                    console.error(errors);
                }

                setPage(data.pageCollection.items[globalVars.getpageid(data.pageCollection,'settings')]);
            });

    }, []);





    if (!page) {
        return "";
    }

    const globalFontColor = page.settings.fontColor;




    const updateMainStageView = (dataIndex) => {
        let activeSlide = document.getElementsByClassName('swiper-slide-active');
        let activeSlideContent = activeSlide[0].children[0];
        let dataSrcImg = activeSlideContent.attributes?.src?.value;
        let dataSrcAlt = activeSlideContent.attributes?.alt?.value;
        let dataSrcVideoStreamUrl = activeSlideContent.getAttribute('data-video-stream-url');
        let dataSrcIndex = "player-carousel-" + activeSlideContent.getAttribute('data-index');
        let dataSrcIndexWr = "player-carousel-" + activeSlideContent.getAttribute('data-index');

        if(dataIndex) {
            dataSrcIndex = "player-carousel-" + activeSlideContent.getAttribute('data-index')+'-'+dataIndex;
            dataSrcIndexWr = "content-carousel-" + activeSlideContent.getAttribute('data-index')+'-'+dataIndex;
        }






            function createVideoElementBasedOnActiveSlide() {

                if(dataSrcVideoStreamUrl) {

                    let sources = [
                            {src: dataSrcVideoStreamUrl, type: 'application/x-mpegURL'}
                        ],
                        config = { stretching: 'none' },
                        tracks = {} ;
                    return (
                        <MediaElement
                            class="stage-visual"
                            id={dataSrcIndex}
                            crossorigin="anonymous"
                            mediaType="video"
                            preload="none"
                            controls
                            width="100%"
                            height="100%"

                            poster={dataSrcImg}
                            sources={JSON.stringify(sources)}
                            options={JSON.stringify(config)}
                            tracks={JSON.stringify(tracks)}
                    />);


                } else {


       return (
           <img src={dataSrcImg} id={dataSrcIndexWr}
                             className="img-to-place-in-stage"
                             alt={dataSrcAlt}
                        />);
                }
            }

        handleStageChange(createVideoElementBasedOnActiveSlide())

    }

    let navStylesNext,navStylesPrev;

 //   window.addEventListener('resize', handleRefresh)


    return (
        <div className="carousel">
            <style>
                {`    
                .swiper-pagination-bullet {
                background-color: `+globalFontColor+`;
            }
                .swiper-pagination-bullet-active {
                background-color: `+globalFontColor+`;
            }
            `}
            </style>


            <Swiper
                navigation={{
                    prevEl: '.carousel-previous',
                    nextEl: '.carousel-next',
                }}
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                loopFillGroupWithBlank={false}
                slidesPerView={3}
                loop={true}
                //centeredSlides={true}
                pagination={{clickable: true}}
                onSlideChangeTransitionEnd={
                    () => {
                        updateMainStageView();

                    }
                }
            >


                {page?.sliderCollection?.items.map((s, index) => {

                    navStylesPrev = {
                        color: globalFontColor,
                        borderRight:" solid 2px "+globalFontColor
                    }

                    navStylesNext = {
                        color: globalFontColor,
                        borderLeft:" solid 2px "+globalFontColor
                    }

                    return (
                        <SwiperSlide key={index}>
                            {s.streamUrl ? (
                                <img src={globalVars.cpagam(s.poster,'url')} alt={s.descriptionAlt}
                                     data-video-stream-url={s.streamUrl}
                                     className="video-to-place-in-stage"
                                     data-index={index}
                                />
                            ) : (
                                <img src={globalVars.cpagam(s.poster,'url')} alt={s.descriptionAlt}
                                     className="img-to-place-in-stage"
                                     data-index={index}

                                />
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="carousel-previous carousel-navigation" role="button" aria-label="Previous slide" style={navStylesPrev}>
            </div>
            <div className="carousel-next carousel-navigation" role="button" aria-label="Next slide" style={navStylesNext}>
            </div>
        </div>

    );
}
export default Carousel;
