import React from 'react';
import showdown  from 'showdown';
import './TextBlock.css'
const converter = new showdown.Converter();



const TextBlock = (props) => {

    if(props.blocktext) {
        return <div className='text-block'>
            <p className='text-block-headline'>{props.blockheadline}</p>
            <p className='text-block-text'><span dangerouslySetInnerHTML={{ __html: converter.makeHtml(props.blocktext).replace('<a ','<a target="_blank" ') }} /></p>
        </div>
    }  else if(props.blockheadline) {
        return <div className='text-block'>
            <p className='text-block-headline'>{props.blockheadline}</p>
            <p className='text-block-text'></p>
        </div>
    }

}
export default TextBlock;
