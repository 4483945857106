import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts/Gotham-Font/Gotham-XLight.otf';
import './fonts/Gotham-Font/Gotham-Bold.otf';
import App from './App';

export function AddJavaScriptCDN(urlOfTheLibrary, integrityCode) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.integrity = integrityCode;
    script.crossOrigin = 'anonymous'
    script.async = true;
    document.head.appendChild(script);
}
export function AddStylesheetCDN(urlOfCssCDN, integrityCode) {
    const styleSheet = document.createElement('link');
    styleSheet.rel = 'stylesheet';
    styleSheet.href = urlOfCssCDN;
    styleSheet.integrity = integrityCode;
    styleSheet.crossOrigin = 'anonymous'
    styleSheet.async = true;
    document.head.appendChild(styleSheet);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App>
      {
          AddJavaScriptCDN(
              'https://cdnjs.cloudflare.com/ajax/libs/mediaelement/5.0.5/mediaelement-and-player.min.js',
              'sha512-9x7Fbp4oRsNE3pMLv5EirYCwa4BPGOuMjQIdeDRd6XSdSm1slQBET2Smn7ehKedVGsaNIgX9Bwo6khHQJJtWzg=='
          )
      }
      {
          AddStylesheetCDN(
              'https://cdnjs.cloudflare.com/ajax/libs/mediaelement/5.0.5/mediaelementplayer.min.css',
              'sha512-uPQKP62rFWcB9WqloOcrcgA5g6DTEGm8MzM2trLIPo56C4S4IlZ9PePhDo+Bcg7xT/ntwWrn0sDSPiuVkSG7DA=='
          )
      }


  </App>
);

