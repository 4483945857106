//import {useState} from "react";

const cpagam = (p,v) => {

    if(p!=null) {
        for(v in p)
        {
            return p[v];
        }
    }
    return "";
}


const getPage = () => {

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let page = urlParams.get('p')
    let anchor = document.location.hash;
    let url = document.location.pathname;

    if(page != null) {
        window.location.href = window.location.pathname + '#' + page.toLowerCase() ;
    } else  if(anchor) {
        let anchor_lang = anchor.split('|');

        if(anchor_lang.length>1) {
            anchor = anchor_lang[0];
        }
        return anchor.replace('#','').toLowerCase();
    } else if (url !== process.env.PUBLIC_URL) {

        let new_url = '';
        let tmp = url.split('/');


        for(let i = 0; tmp.length>i; i++) {


            if (i && i < tmp.length- 1) {
                new_url += '/';
            } else if (i) {
                new_url += '/#';
            }
            new_url += tmp[i];

        }

        window.location.href = new_url;
    }


    return '404';
}

const getLang = () => {

    let anchor = document.location.hash;
    let lang = 'en-US';

    if(anchor!=null) {
        let anchor_lang = anchor.split('|');

        if(anchor_lang.length>1) {
            lang = anchor_lang[1];
        }
    }

    return lang;
}

function hextoRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}


function getRGB(str){
    let match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    return match ? {
        r: match[1],
        g: match[2],
        b: match[3],
        a: (typeof match[4]!=="undefined"?match[4]:"1")
    } : {};
}

const getPageid = (page,subList) => {


    let pageid = -1;

    let settings = page.items;

    let gpage = getPage();


    for (const key in settings) {

        let comp;

        if(subList) {
            comp = settings[key][subList]?.uriPathSlug;
        } else {
            comp = settings[key]?.uriPathSlug;
        }

        if(gpage === comp) {
            pageid = key;
        }
    }


    return pageid;
}


function useWindowSize() {
        window.removeEventListener('resize', useWindowSize);
        return window.innerWidth * window.innerHeight
}




// eslint-disable-next-line import/no-anonymous-default-export
export default {
    activeLang : getLang(),
    cpagam: cpagam,
    getpage: getPage(),
    getpageid: getPageid,
    hexToRgb: hextoRgb,
    getRGB: getRGB,
    useWindowSize: useWindowSize

};

